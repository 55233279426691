import React from 'react'
import Header from './header'
import "../styles/style.scss"
// import '../styles/layout.css'
// import styles from './layout.module.css'

const Layout = ({ children, onHideNav, onShowNav, showNav, siteTitle, address, pages, mainImage }) => (
  <>
    <section className="section">
      <div className="container">
        <Header siteTitle={siteTitle} pages={pages} onHideNav={onHideNav} onShowNav={onShowNav} showNav={showNav} pages={pages} mainImage={mainImage} />
        {children}
        <footer className="footer" role="contentinfo">
          <div className="content has-text-centered">
            <p className="copyright">
              &copy; {new Date().getFullYear()} {siteTitle}
            </p>
          </div>
          <div className="content has-text-centered">
            <div>{address}</div>
          </div>
          <div className="content has-text-centered">
            <a className="button" href="https://www.facebook.com/oultonvillagehallstonestaffs/">
              <span className="icon">
                <i className="fab fa-facebook"></i>
              </span>
              <span>Visit us on Facebook</span>
            </a>
          </div>
        </footer>
        {/* <div className={styles.content}>{children}</div>
        <footer className={styles.footer}>
          <div className={styles.footerWrapper}>
            <div className={styles.siteInfo}>
              &copy; {new Date().getFullYear()} Oulton Village Hall
            </div>
          </div>
        </footer> */}
      </div>
    </section>
  </>
)

export default Layout
