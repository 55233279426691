import { graphql, StaticQuery } from 'gatsby'
import React, { useState } from 'react'
import Layout from '../components/layout'

const query = graphql`
  query SiteTitleQuery {
    site: sanitySiteSettings(_id: {regex: "/(drafts.|)siteSettings/"}) {
      title
      address
      mainImage {
        ...SanityImage
        alt
      }
    }
    pages: allSanityPage(
      filter: { slug: { current: { nin: [null, "home"] }}}
      sort: { fields: [order], order: ASC }
    ) {
      edges {
        node {
          id
          order
          title
          showInNav
          slug {
            current
          }
        }
      }
    }
  }
`

function LayoutContainer(props) {
  const [showNav, setShowNav] = useState(false)
  function handleShowNav() {
    setShowNav(true)
  }
  function handleHideNav() {
    setShowNav(false)
  }
  return (
    <StaticQuery
      query={query}
      render={data => {
        if (!data.site) {
          throw new Error(
            'Missing "Site settings". Open the Studio at http://localhost:3333 and some content in "Site settings"'
          )
        }
        return (
          <Layout
            {...props}
            showNav={showNav}
            siteTitle={data.site.title}
            address={data.site.address}
            pages={data.pages}
            mainImage={data.site.mainImage}
            onHideNav={handleHideNav}
            onShowNav={handleShowNav}
          />
        )
      }}
    />
  )
}

export default LayoutContainer
