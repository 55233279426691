import { Link } from 'gatsby'
import React from 'react'
import Icon from './icon'
import { cn } from '../lib/helpers'
import { buildImageObj } from '../lib/helpers'
import { imageUrlFor } from '../lib/image-url'

// import styles from './header.module.css';

const Header = ({ onHideNav, onShowNav, showNav, siteTitle, pages, mainImage }) => (
  <>
    <header className="header__main is-vertical-center" role="banner" style={{ backgroundImage: `url('${imageUrlFor(buildImageObj(mainImage))}')` }}>
      <div className="logo header">
        <a href="/">
          <h1 className="title has-text-centered is-1 is-size-3-mobile has-text-white">{siteTitle}</h1>
          {/* <h3 class="subtitle has-text-centered is-3 is-size-5-mobile"><?</h3> */}
        </a>
      </div>
    </header>
    <div className="tabs is-medium is-centered is-size-6-mobile">
      <ul>
        <li id="menu-item-403" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-403"><Link to='/'>Home</Link></li>
        <li id="menu-item-403" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-403"><Link to='/news-and-events/'>News &amp; Events</Link></li>
        {
          pages.edges.filter((page) => (page.node.showInNav === true)).map((page, idx) => {
            return <li key={idx} id="menu-item-403" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-403"><Link to={`/${page.node.slug.current}/`}>{page.node.title}</Link></li>
          })
        }
        {/* <li id="menu-item-403" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-403">
          <a href={'https://v2.hallmaster.co.uk/Scheduler/View/10915?startRoom=0'} target='_blank'>Calendar</a>
        </li> */}
        <li id="menu-item-403" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-403">
          <Link to='/contact/'>Contact</Link>
        </li>

        {/* <li id="menu-item-23" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-23"><a href="https://oultonvillagehall.co.uk/whats-on/">What’s on</a></li>
        <li id="menu-item-111" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-111"><a href="https://oultonvillagehall.co.uk/facilities/">Facilities</a></li>
        <li id="menu-item-110" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-110"><a href="https://oultonvillagehall.co.uk/bookings/">Booking Information</a></li>
        <li id="menu-item-122" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-122"><a href="https://oultonvillagehall.co.uk/gallery/">Gallery</a></li>
        <li id="menu-item-109" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-109"><a href="https://oultonvillagehall.co.uk/village-links/">Village Links</a></li>
        <li id="menu-item-387" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-387"><a href="https://oultonvillagehall.co.uk/lottery-funding/">Funding</a></li>
        <li id="menu-item-24" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-24"><a href="https://oultonvillagehall.co.uk/contact/">Contact</a></li> */}
      </ul>
    </div>
  </>
  // <div>
  //   {
  //     console.log({ mainImage })
  //   }
  //   {mainImage && mainImage.asset && (
  //     <div className={styles.mainImage}>
  //       <img
  //         src={imageUrlFor(buildImageObj(mainImage))
  //           .width(1200)
  //           .height(Math.floor((9 / 16) * 1200))
  //           .fit('crop')
  //           .auto('format')
  //           .url()}
  //         alt={mainImage.alt}
  //       />
  //     </div>
  //   )}
  //   <div className={styles.wrapper}>
  //     <div className={styles.branding}>
  //       <Link to='/'>{siteTitle}</Link>
  //     </div>

  //     <button className={styles.toggleNavButton} onClick={showNav ? onHideNav : onShowNav}>
  //       <Icon symbol='hamburger' />
  //     </button>

  //     <nav className={cn(styles.nav, showNav && styles.showNav)}>
  //       <ul>
  //         <li>
  //           <Link to='/'>Home</Link>
  //         </li>
  //         <li>
  //           <Link to='/news-and-events/'>News &amp; Events</Link>
  //         </li>
  //         {
  //           pages.edges.map((page, idx) => {
  //             return <li key={`basic-page-${idx}`} > <Link to={`/${page.node.slug.current}/`}>{page.node.title}</Link></li>
  //           })
  //         }
  //         <li>
  //           <Link to='/contact/'>Contact</Link>
  //         </li>
  //       </ul>
  //     </nav>
  //   </div>
  // </div >
)

export default Header
